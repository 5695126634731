import { Injectable } from '@angular/core';
import { Address, City } from '@prc-lib-core';
import { AnswerWorkKeyType } from '@prc-serenity-store/reducers/answers-works.reducer';
import { UserKeyType } from '@prc-serenity-store/reducers/user.reducer';
import { StoreKeys } from '../../configs/store.config';
import { AnswserKeys } from '../../models/keys.model';
import { AnswerKeyType } from '../../store/reducers/answers.reducer';

@Injectable({
  providedIn: 'root',
})
export class AnswerService {
  constructor(private readonly window: Window) {}

  // @TODO: it's similar to answer service in SEE project
  // Should figure out a way to move it to commun lib.
  getAnswerFromLocalStorage<T>(answerKey: AnswerKeyType): T {
    const answerStorage = this.window.localStorage.getItem('prc-serenity-' + StoreKeys.Answers) ?? '{}';
    return JSON.parse(answerStorage)[answerKey] ?? undefined;
  }

  getAnswersWorksFromLocalStorage<T>(answerKey: AnswerWorkKeyType): T {
    const answerStorage = this.window.localStorage.getItem('prc-serenity-' + StoreKeys.AnswersWorks) ?? '{}';
    return JSON.parse(answerStorage)[answerKey] ?? undefined;
  }

  getUserFromLocalStorage<T>(userKey: UserKeyType): T {
    const answerStorage = this.window.localStorage.getItem('prc-serenity-' + StoreKeys.User) ?? '{}';
    return JSON.parse(answerStorage)[userKey] ?? undefined;
  }

  getZipCode(): string {
    // @TODO : It should probably be used another way
    const mainResidenceZipCode = this.getAnswerFromLocalStorage<City>(AnswserKeys.HousingZipCode);
    if (mainResidenceZipCode) {
      return mainResidenceZipCode?.code_postal;
    } else {
      return this.getAnswerFromLocalStorage<Address>(AnswserKeys.WorksAddress)?.postalCode;
    }
  }
}
